var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"overflow-auto"},[_c('konva-stage',{ref:"stage",attrs:{"config":{ width: 1200, height: 678 }}},[_c('konva-layer',{ref:"layer"},[_c('konva-image',{attrs:{"config":{ image: _vm.fairPlaneImg }}}),_vm._l((_vm.places),function(place){return _c('konva-group',{key:place.id,attrs:{"config":{
            x: Number(place.xCoordinate),
            y: Number(place.yCoordinate)
          }},on:{"mouseenter":_vm.handleMouseEnter,"click":function($event){return _vm.showPavilion(place)},"mouseleave":_vm.handleMouseLeave}},[_c('konva-image',{attrs:{"config":{
            image: _vm.fairPinIconImg,
            width: 40,
            height: 40,
            x: -20,
            y: -40
          }}}),_c('konva-rect',{attrs:{"config":{
              x: Number(0 - 75),
              y: Number(0),
              width: 150,
              height: 20,
              stroke: place.color,
              fill: '#fff',
              strokeWidth: 1
            }}}),_c('konva-text',{attrs:{"config":{
               x: - 100,
              y: 0,
              text: place.name,
              fontSize: 13,
              fontFamily: 'Roboto',
              fill: '#555',
              width: 200,
              padding: 5,
              align: 'center'
            }}})],1)})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }