<template>
  <div class="no-gutter justify-center">
    <div class="text-center mt-12">
      <img :src="iconOk"/>
      <p>
        <span style="font-size: 20px;">
          <strong> Tu solicitud de stand fue generada correctamente. </strong>
        </span>
        <br/>
        Recibirás un correo con las indicaciones a seguir.
      </p>
      <a
        href="http://fise.co"
        class="mt-4 elevation-0 text-none mr-2 v-btn v-btn--contained theme--light v-size--default primary"
      >Regresar a la página de inicio</a
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'StandSelectionSuccessful',
  data: function () {
    return {
      iconOk: require(`@/assets/images/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/icon-ok.jpg`)
    }
  }
}
</script>
