<template>
  <div class="d-flex justify-center">
    <div class="overflow-auto">
      <konva-stage ref="stage" :config="{ width: 1200, height: 678 }">
        <konva-layer ref="layer">
          <konva-image :config="{ image: fairPlaneImg }"/>
          <konva-group
            v-for="place in places"
            :key="place.id"
            @mouseenter="handleMouseEnter"
            @click="showPavilion(place)"
            @mouseleave="handleMouseLeave"
            :config="{
              x: Number(place.xCoordinate),
              y: Number(place.yCoordinate)
            }"
          >
            <konva-image
              :config="{
              image: fairPinIconImg,
              width: 40,
              height: 40,
              x: -20,
              y: -40
            }"
            />
            <konva-rect
              :config="{
                x: Number(0 - 75),
                y: Number(0),
                width: 150,
                height: 20,
                stroke: place.color,
                fill: '#fff',
                strokeWidth: 1
              }"
            >
            </konva-rect>
            <konva-text
              :config="{
                 x: - 100,
                y: 0,
                text: place.name,
                fontSize: 13,
                fontFamily: 'Roboto',
                fill: '#555',
                width: 200,
                padding: 5,
                align: 'center'
              }"
            >
            </konva-text>
          </konva-group>
        </konva-layer>
      </konva-stage>
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'FairPlane',
  props: {
    pinIconUrl: {
      default: null,
      type: String
    },
    planeImageUrl: {
      default: null,
      type: String
    },
    places: {
      default: () => [],
      type: Array
    },
    showPavilion: {
      type: Function
    }
  },
  created () {
    const fairPlane = new Image()
    fairPlane.src = this.planeImageUrl
    fairPlane.onload = () => {
      this.fairPlaneImg = fairPlane
    }

    const fairPinIcon = new Image()
    fairPinIcon.src = this.pinIconUrl
    fairPinIcon.onload = () => {
      this.fairPinIconImg = fairPinIcon
    }
  },
  methods: {
    handleMouseEnter (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'pointer'
    },
    handleMouseLeave (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'default'
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin],
  components: {},
  data () {
    return {
      fairPlaneImg: null,
      fairPinIconImg: null
    }
  }
}
</script>
