<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>Gerente de la empresa</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Nombre gerente"
          rules="required|max:80"
        >
          <v-text-field
            vid="CEOName"
            v-model="model.CEOName"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Nombre"
            required
          ></v-text-field>
        </validation-provider>
      </div>

      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Correo electrónico gerente"
          rules="required|email"
        >
          <v-text-field
            vid="CEOEmail"
            v-model="model.CEOEmail"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Correo electrónico"
            required
          ></v-text-field>
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Cargo gerente"
          rules="required|max:80"
        >
          <v-text-field
            vid="CEOPositionDetail"
            v-model="model.CEOPositionDetail"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Cargo"
            required
          ></v-text-field>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-wrap">
          <div class="flex-grow-1">
            <validation-provider
              v-slot="{ errors }"
              name="Teléfono gerente"
              :rules="{required: true,phone:true}"
            >
              <vue-tel-input-vuetify
                label="Teléfono"
                placeholder=""
                :error-messages="errors"
                mode="international"
                :inputOptions="{showDialCode:false}"
                required
                outlined
                dense
                v-model="model.CEOPhone"
                :preferred-countries="['CO', 'US']"
              ></vue-tel-input-vuetify>
            </validation-provider>
          </div>
          <div class="flex-grow-1">
            <validation-provider
              v-slot="{ errors }"
              name="Extensión"
              rules="integer"
            >
              <v-text-field
                vid="CEOPhoneExtension"
                v-model="model.CEOPhoneExtension"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Extensión"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>

    <div class="text-h5 red--text">
      <span class="font-bold">Nota:</span>
      Los <span class="font-italic">Contactos {{ appName }}</span>
      , son las personas que recibirán todas las comunicaciones para la participación en la Feria.  Por lo anterior, es importante que tengan la disponibilidad para leer y analizar la información enviada y en caso de ser necesario, responder oportunamente.
      <br>El primer y segundo contacto no pueden tener el mismo correo y deben ser únicos por reserva. (No deben haber sido usados en otra reserva de esta feria)
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Primer contacto para {{ appName }}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Nombre primer contacto"
              rules="required|max:80"
            >
              <v-text-field
                vid="firstContactName"
                v-model="model.firstContactName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Correo electrónico primer contacto"
                  rules="required|email|firstContactEmail:@secondContactEmail|forbiddenUsernames"
                  vid="firstContactEmail"
                >
                  <v-text-field
                    v-model="model.firstContactEmail"
                    type="email"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Correo electrónico"
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </validation-provider>
              </template>
              <span>Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www</span>
            </v-tooltip>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Cargo primer contacto"
              rules="required|max:80"
            >
              <v-text-field
                vid="firstContactOccupationDetail"
                v-model="model.firstContactPositionDetail"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Cargo"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Teléfono primer contacto"
                  :rules="{phone:true}"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono"
                    mode="international"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                    v-model="model.firstContactPhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    vid="CEOPhoneExtension"
                    v-model="model.firstContactPhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Celular primer contacto"
              :rules="{required:true,phone:true}"
            >
              <vue-tel-input-vuetify
                label="Celular"
                :error-messages="errors"
                mode="international"
                :inputOptions="{showDialCode:false}"
                required
                outlined
                dense
                v-model="model.firstContactMobilePhone"
                :preferred-countries="['CO', 'US']"
              ></vue-tel-input-vuetify>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Segundo contacto para {{ appName }}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Nombre segundo contacto"
              rules="required|max:80"
            >
              <v-text-field
                vid="secondContactName"
                v-model="model.secondContactName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Correo electrónico segundo contacto"
                  rules="required|email|secondContactEmail:@firstContactEmail|forbiddenUsernames"
                  vid="secondContactEmail"
                >
                  <v-text-field
                    v-model="model.secondContactEmail"
                    type="email"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Correo electrónico"
                    required
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </validation-provider>
              </template>
              <span>Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www</span>
            </v-tooltip>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Cargo segundo contacto"
              rules="required|max:80"
            >
              <v-text-field
                vid="secondContactOccupationDetail"
                v-model="model.secondContactPositionDetail"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Cargo"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Teléfono segundo contacto"
                  :rules="{phone:true}"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono"
                    :error-messages="errors"
                    mode="international"
                    required
                    outlined
                    dense
                    v-model="model.secondContactPhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    vid="CEOPhoneExtension"
                    v-model="model.secondContactPhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              v-slot="{ errors }"
              name="Celular segundo contacto"
              :rules="{required:true,phone:true}"
            >
              <vue-tel-input-vuetify
                label="Celular"
                :error-messages="errors"
                mode="international"
                :inputOptions="{showDialCode:false}"
                required
                outlined
                dense
                v-model="model.secondContactMobilePhone"
                :preferred-countries="['CO', 'US']"
              ></vue-tel-input-vuetify>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'ContactInfo',
  props: {
    model: {
      default: null,
      type: Object
    }
  },
  created () {
  },
  methods: {},
  mixins: [crudServiceMixin, loaderMixin, notificationMixin],
  components: {},
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  }
}
</script>
