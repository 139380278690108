var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Espacio: "+_vm._s(_vm.place.name))])]),_c('div',{staticClass:"col-9 pt-0 pb-0"},[_c('v-chip',{staticClass:"ml-0 mr-2",attrs:{"color":"green","text-color":"white","label":""}},[_vm._v(" Disponible ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white","color":"#E3A728","label":""}},[_vm._v(" Pre-reservado ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white","color":"red","label":""}},[_vm._v(" Reservado ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"default","label":""}},[_vm._v(" Seleccionado ")])],1),_c('div',{staticClass:"col-3 text-right pt-0 pb-0"},[_c('v-btn',{staticClass:"elevation-0 text-none",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('reloadGeneralPlane')}}},[_vm._v(" Regresar al plano general ")])],1)]),_c('div',{staticClass:"d-flex justify-center mt-5"},[(_vm.place)?_c('div',[_c('konva-stage',{ref:"stage",attrs:{"config":{ width: _vm.place.planeWidth, height: _vm.place.planeHeight }}},[_c('konva-layer',{ref:"layer"},[_c('konva-image',{attrs:{"config":{ image: _vm.userPlaneImageUrl }}}),_vm._l((_vm.stands),function(stand){return _c('div',{key:stand.id},[(!stand.cornerType)?_c('konva-group',{attrs:{"config":{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
              }},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.bookStand(stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.width),
                  height: Number(_vm.factor*stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color
                }}}),_c('konva-text',{attrs:{"config":{
                  x: (Number(_vm.factor*stand.width) / 2) - (_vm.factor * stand.width > 50 ? 25 : 20),
                  y: (Number(_vm.factor*stand.depth) / 2) - 10,
                  text: stand.code.toUpperCase(),
                  fontSize: _vm.defineFont(stand),
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: _vm.defineColor(stand),
                  padding: 5,
                  align: 'center'
                }}})],1):_vm._e(),(stand.cornerType === 'SIZQ')?_c('konva-group',{attrs:{"config":{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
              }},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.bookStand(stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.width),
                  height: Number(_vm.factor*stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference),
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference),
                  width: Number(_vm.factor*stand.widthDifference),
                  height: Number(_vm.factor*stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                  strokeWidth: 4
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference) - 2,
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference),
                  width: 2,
                  height: Number(_vm.factor*stand.depthDifference) + 1,
                  fill: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference) - 2,
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference) - 2,
                  width: Number(_vm.factor*stand.widthDifference) + 3,
                  height: 2,
                  fill: _vm.place.color
                }}}),_c('konva-text',{attrs:{"config":{
                  x: (Number(_vm.factor*stand.width) / 2) - 25,
                  y: 20,
                  text: stand.code.toUpperCase(),
                   fontSize: _vm.defineFont(stand),
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: _vm.defineColor(stand),
                  padding: 5,
                  align: 'center'
                }}})],1):_vm._e(),(stand.cornerType === 'SDER')?_c('konva-group',{attrs:{"config":{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
              }},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.bookStand(stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.width),
                  height: Number(_vm.factor*stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference),
                  width: Number(_vm.factor*stand.widthDifference),
                  height: Number(_vm.factor*stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                  strokeWidth: 4
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.widthDifference),
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference) - 2,
                  width: 2,
                  height: (Number(_vm.factor*stand.depthDifference)) + 3,
                  fill: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: Number(_vm.factor*stand.depth) - Number(_vm.factor*stand.depthDifference) - 2,
                  width: (Number(_vm.factor*stand.widthDifference)) + 1,
                  height: 2,
                  fill: _vm.place.color
                }}}),_c('konva-text',{attrs:{"config":{
                  x: (Number(_vm.factor*stand.width) / 2) - 25,
                  y: 20,
                  text: stand.code.toUpperCase(),
                  fontSize: _vm.defineFont(stand),
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: _vm.defineColor(stand),
                  padding: 5,
                  align: 'center'
                }}})],1):_vm._e(),(stand.cornerType === 'IIZQ')?_c('konva-group',{attrs:{"config":{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
              }},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.bookStand(stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.width),
                  height: Number(_vm.factor*stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference),
                  y: 0,
                  width: Number(_vm.factor*stand.widthDifference),
                  height: Number(_vm.factor*stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                  strokeWidth: 4
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference) - 2,
                  y: 0,
                  width: 2,
                  height: Number(_vm.factor*stand.depthDifference) + 2,
                  fill: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.width)  - Number(_vm.factor*stand.widthDifference),
                  y: Number(_vm.factor*stand.depthDifference),
                  width: Number(_vm.factor*stand.widthDifference) + 1,
                  height: 2,
                  fill: _vm.place.color
                }}}),_c('konva-text',{attrs:{"config":{
                  x: (Number(_vm.factor*stand.width) / 2) - 25,
                  y: (Number(_vm.factor*stand.depth) / 2) + 20,
                  text: stand.code.toUpperCase(),
                  fontSize: _vm.defineFont(stand),
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: _vm.defineColor(stand),
                  padding: 5,
                  align: 'center'
                }}})],1):_vm._e(),(stand.cornerType === 'IDER')?_c('konva-group',{attrs:{"config":{
                  x: Number(stand.xCoordinate),
                  y: Number(stand.yCoordinate),
              }},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.bookStand(stand)}}},[_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.width),
                  height: Number(_vm.factor*stand.depth),
                  fill: 'white',
                  stroke: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: 0,
                  width: Number(_vm.factor*stand.widthDifference),
                  height: Number(_vm.factor*stand.depthDifference),
                  fill: 'white',
                  stroke: 'white',
                  strokeWidth: 4
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: Number(_vm.factor*stand.widthDifference),
                  y: 0,
                  width: 2,
                  height: Number(_vm.factor*stand.depthDifference),
                  fill: _vm.place.color
                }}}),_c('konva-rect',{attrs:{"config":{
                  x: 0,
                  y: Number(_vm.factor*stand.depthDifference),
                  width: Number(_vm.factor*stand.widthDifference) + 2,
                  height: 2,
                  fill: _vm.place.color
                }}}),_c('konva-text',{attrs:{"config":{
                  x: (Number(_vm.factor*stand.width) / 2) - 25,
                  y: (Number(_vm.factor*stand.depth) / 2) + 20,
                  text: stand.code.toUpperCase(),
                  fontSize: _vm.defineFont(stand),
                  fontFamily: 'Roboto',
                  fontStyle: 'bold',
                  fill: _vm.defineColor(stand),
                  padding: 5,
                  align: 'center'
                }}})],1):_vm._e()],1)})],2)],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }